@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* background-color: #0c0c0c; */
    background-color:#000000;
}

@font-face {
    font-family: "MartianMono";
    src: url("../public/MartianMono.ttf");
  }

@layer base {
    html {
      @apply text-zinc-100 font-martian;
    } 
  }